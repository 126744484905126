<template>
    <div class="relative w-full">
        <div class="select">
            <input v-model.trim="value"
                   class="form__input h-full border-none focus:outline-none hover:outline-none"
                   placeholder="Type to search"
                   @focus="showDropDown"
                   @blur.stop="closeDropDown"
                   @keyup="handler"
            >
            <div class="flex flex-col">
                <font-awesome-icon icon="angle-up" />
                <font-awesome-icon icon="angle-down" />
            </div>
        </div>
        <div class="dropDown" :class="{active: open}">
            <slot name="content">
                searching...
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        search: {
            type: Function,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            open: false,
            value: '',
        };
    },
    methods: {
        showDropDown() {
            this.open = true;
        },
        closeDropDown() {
            this.open = false;
        },
        async handler() {
            await this.search({ value: this.value });
        },
    },
};
</script>

<style lang="scss" scoped>
.select {
    @apply h-12;
    @apply rounded-md;
    @apply px-2;
    @apply w-full;
    @apply border;
    @apply border-solid;
    @apply border-gray-300;
    @apply text-sm;
    @apply bg-white;
    @apply text-gray-700;
    @apply font-medium;
    @apply flex;
    @apply items-center;
    @apply justify-between;
}
.dropDown {
    @apply absolute;
    @apply h-0;
    @apply overflow-hidden;
    @apply w-full;
    @apply top-full;
    @apply rounded-md;
    @apply bg-white;
}
.dropDown.active {
    @apply h-auto;
    @apply shadow-md;
}
</style>
