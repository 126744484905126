<template>
    <vue-dropzone
        id="dropzone"
        ref="dropzone"
        :options="dropOptions"
        @vdropzone-complete="afterComplete"
        @vdropzone-file-added="onUploadState"
        @vdropzone-canceled="removeAllFiles"
        @vdropzone-removed-file="removeAllFiles"
    />
</template>

<script>
import vueDropzone from 'vue2-dropzone';
import { getAccessToken } from '../../utils/api/http/auth/auth';
import { getAssetsURL } from '../../utils/api/http/assets/assets';

export default {
    components: {
        vueDropzone,
    },
    props: {
        getURL: {
            type: Function,
            default: () => {},
        },
        getUploadStatus: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            dropOptions: {
                url: getAssetsURL(),
                maxFilesize: 2,
                maxFiles: 4,
                addRemoveLinks: true,
                headers: {
                    Authorization: `Bearer ${getAccessToken()}`,
                },
            },
        };
    },
    methods: {
        onUploadState() {
            this.getUploadStatus({ isUploading: true });
        },
        afterComplete(file) {
            if (file) {
                const response = JSON.parse(file.xhr.responseText);
                const { url } = response.data;
                this.getURL(url);
                this.getUploadStatus({ isUploading: false });
            }
        },
        removeAllFiles() {
            this.$refs.dropzone.removeAllFiles();
        },
    },
};
</script>
