<template>
    <section class="item">
        <div class="item__left">
            <img v-if="collaborator.profile"
                 :src="collaborator.profile"
                 alt=""
                 class="h-8 w-8 rounded-full overflow-hidden"
            >
            <div v-else class="user__name">
                {{ getAbv(collaborator.name) }}
            </div>
            <p class="text-gray-700 text-sm font-medium ml-2">
                {{ collaborator.email }}
            </p>
        </div>
        <div class="item__right">
            <button class="item__button outline-none"
                    type="button"
                    @click="removeCollaborator(collaborator)"
            >
                <font-awesome-icon icon="times" class="text-xs" />
            </button>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        collaborator: {
            default: () => ({}),
            required: false,
            type: Object,
        },
        remove: {
            default: () => {},
            required: false,
            type: Function,
        },
    },

    methods: {
        removeCollaborator(collaborator = {}) {
            this.remove(collaborator);
        },

        getAbv(name = '') {
            if (!name) return '';
            const nameParts = name.split(' ');
            if (nameParts.length > 1) {
                const firstName = nameParts[0];
                const lastName = nameParts[1];
                return `${firstName[0]}${lastName[0]}`;
            }
            if (nameParts.length === 1) {
                const firstName = nameParts[0];
                return firstName[0];
            }
            return '';
        },
    },
};
</script>

<style lang="scss" scoped>
.item{
    @apply h-12;
    @apply w-full;
    @apply rounded-md;
    @apply shadow-sm;
    @apply flex;
    @apply items-center;
    @apply justify-between;
    @apply border;
    @apply border-solid;
    @apply border-gray-300;
    @apply px-3;
    @apply my-1;
}
.item__left{
    @apply flex;
    @apply h-full;
    @apply items-center;
}
.item__text{
    @apply text-gray-700;
    @apply text-sm;
    @apply font-medium;
    @apply ml-2;
}
.item__right{
    @apply flex;
    @apply h-full;
    @apply items-center;
}
.item__button{
    @apply rounded-full;
    @apply h-6;
    @apply w-6;
    @apply border;
    @apply border-gray-300;
    @apply grid;
    @apply place-items-center;
    @apply text-gray-700;
}

.item__button:focus {
    @apply outline-none;
}

.item__button:hover{
    @apply text-accent;
}

.user__name {
    @apply font-medium;
    @apply text-sm;
    @apply text-white;
    @apply rounded-full;
    @apply bg-accent;
    @apply h-8;
    @apply w-8;
    @apply grid;
    @apply place-items-center;
}
</style>
