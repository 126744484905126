<template>
    <div class="checkbox" :class="{active: isActive}">
        <input type="checkbox"
               class="checkbox__input"
               @change="onCheckboxClick"
        >
        <div class="checkbox__content">
            <div class="checkbox__checkbox">
                <font-awesome-icon v-if="isActive"
                                   :icon="['fas', 'check']"
                                   class="checkbox__icon"
                />
            </div>
            <p class="checkbox__title">
                {{ name }}
            </p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            default: '',
        },
        className: {
            type: String,
            default: '',
        },
        onChange: {
            type: Function,
            default: () => {},
        },
        meta: {
            type: Object,
            default: () => {},
        },
        isChecked: {
            type: Boolean,
        },
    },
    data() {
        return {
            isActive: this.isClicked || false,
        };
    },
    watch: {
        isChecked: {
            deep: true,
            immediate: true,
            handler(state) {
                this.isActive = state;
            },
        },
    },
    methods: {
        toggleIsActive() {
            this.isActive = !this.isActive;
        },

        async onCheckboxClick() {
            this.toggleIsActive();
            if (this.isActive) {
                await this.onChange(true, this.meta);
                return;
            }
            await this.onChange(false, this.meta);
        },
    },
};
</script>

<style lang="scss" scoped >
.checkbox{
    @apply h-12;
    min-width: 120px;
    @apply relative;
    @apply text-gray-700;
    @apply overflow-hidden;
    &:not(:last-of-type){
        @apply mr-3;
    }
}
.checkbox__input{
    @apply absolute;
    @apply w-full;
    @apply left-0;
    @apply h-full;
    @apply z-10;
    @apply opacity-0;
    @apply cursor-pointer;
}
.checkbox__content{
    @apply w-full;
    @apply h-full;
    @apply p-2;
    @apply flex;
    @apply items-center;
    @apply flex-row;
}

.checkbox__title{
    @apply ml-4;
    @apply flex;
    @apply text-sm;
    @apply items-center;
    @apply justify-center;
    color: currentColor;
}
.checkbox__checkbox{
    @apply h-6;
    @apply w-6;
    @apply border;
    @apply border-solid;
    @apply border-gray-300;
    @apply rounded-md;
    @apply flex;
    @apply text-accent;
    @apply justify-center;
    @apply items-center;
}
.checkbox__icon{
    color: currentColor;
    @apply text-xs;
}
</style>
