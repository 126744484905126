/* eslint-disable import/prefer-default-export */
import { BASE_URL } from '../../../constants';
import invoke from '../../invoke';

export function getAssetsURL() {
    return `${BASE_URL.api}/assets/upload`;
}

export async function uploadImage(file) {
    const formdata = new FormData();
    formdata.append('file', file);
    const res = await invoke(
        'POST',
        '/assets/upload',
        formdata,
        { json: false },
    );
    return res.url;
}

export function UploadAdapter(loader) {
    this.loader = loader;
    this.upload = async () => {
        const file = await loader.file;
        const url = await uploadImage(file);
        return { default: url };
    };
    this.abort = () => {};
}
